
import { computed } from "vue";
import { Marker } from "vue3-google-map";

export default {
    name: "AssetMapMarker",
    components: {
        Marker,
    },
    props: {
        asset: Object,
        hoveredAsset: [Number, String, null],
    },
    setup(props) {
        const options = computed(() => {
            const isSelected = props.hoveredAsset && props.asset.id == props.hoveredAsset;
            return {
                position: props.asset.geom,
                label: {
                  fontSize: isSelected ? '14px' : '10px',
                  fontWeight: isSelected ? 'bold' : 'normal',
                  text: props.asset.id.toString(),
                  color: isSelected ? '#0d6efd' : 'black',
                },
                icon: isSelected
                  ? {
                    url: 'media/icons/circle.png',
                    scaledSize: { width: 32, height: 32 },
                    origin: { x: 0, y: 0 },
                    anchor: { x: 16, y: 40 }
                  }
                  : null,
                title: props.asset.name || props.asset.title,
                zIndex: props.asset.id == props.hoveredAsset ? 10000 : null,
            };
        });
        return { options, asset: props.asset };
    },
};
