
import { mapState, mapGetters } from 'vuex';
import AssetListItem from "./AssetListItem.vue";
import AssetListItemForm from "./AssetListItemForm.vue";
import CommonSearchField from "../common/SearchField.vue";

export default {
  name: "AssetList",
  components: {
    AssetListItem,
    AssetListItemForm,
    CommonSearchField,
  },
  props: {
    widgetClasses: String,
    hoveredAsset: [ Number, String, null ],
  },
  data: () => ({
    isAddingActive: false,
    searchQuery: null,
    isTriggeredInside: false,
    scrollAttempts: 0,
  }),
  computed: {
    ...mapState('assets__new', [
      'getAssetsResult',
      'getAssetsIsLoading',
      'getAssetsError',
    ]),
    ...mapGetters('assets__new', [
      'getAssetsResultFiltered',
    ]),
    assets() {
      return this.getAssetsResultFiltered;
    },
  },
  methods: {
    toggleAdd() {
      this.isAddingActive = !this.isAddingActive;
    },
    cancelAdd() {
      this.isAddingActive = false;
    },
    setSearchQuery(query) {
      if (!query) {
        this.searchQuery = null;
      } else {
        this.searchQuery = query;
      }
    },
    hoverHandler(id) {
      this.isTriggeredInside = true;
      this.$emit('set-hovered-asset', id);
    },
    reqursiveScrollToHovered(assetId) {
      if (this.isTriggeredInside) {
        return;
      }
      const ref = this.$refs['hovered' + assetId]?.[0];
      if (!ref) {
        if (this.scrollAttempts < 10) {
          console.log('attempt++');
          this.scrollAttempts++;
          this.$nextTick(this.reqursiveScrollToHovered(assetId));
        }
        return;
      }
      ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.scrollAttempts = 0;
    },
  },
  watch: {
    searchQuery() {
      this.$store.state.assets__new.searchQuery = this.searchQuery;
    },
    hoveredAsset: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.reqursiveScrollToHovered(newVal);
          this.isTriggeredInside = false;
        }
      },
    },
  },
}
