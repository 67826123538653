
import { mapState } from 'vuex';
import AssetListItemForm from "./AssetListItemForm.vue";

export default {
  name: "AssetListItem",
  components: {
    AssetListItemForm,
  },
  props: {
    asset: Object,
    hoveredAsset: [ Number, String, null ],
  },
  data: () => ({
    isRemoveConfirmationNeeded: false,
    isEditActive: false,
    isLoading: false,
  }),
  computed: {
    ...mapState('assets__new', [
      'removeAssetsResult',
      'removeAssetsIsLoading',
      'removeAssetsError',
    ]),
  },
  methods: {
    remove() {
      this.isRemoveConfirmationNeeded = true;
    },
    cancelRemove() {
      this.isRemoveConfirmationNeeded = false;
    },
    requestRemove() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$store.dispatch("assets__new/removeAssetsItem", {
        id: this.asset.id,
        onSuccess: () => {
          this.$store.dispatch("assets__new/getAssets");
        },
      });
    },
    edit() {
      this.isEditActive = !this.isEditActive;
    },
    cancelEdit() {
      this.isEditActive = false;
    },
  },
}
